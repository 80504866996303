import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "beijing.png" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "foshan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.mainland" description="seo.desc.main" />
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.mainland.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">
                  <a className="anchor" id="mainland_news" />
                  {tt("pages.mainland.title2")}
                </h5>
                <div className="text-dark">{tt("pages.mainland.desc1")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">
                  <a className="anchor" id="beijing" />
                  {tt("pages.mainland.title3")}
                </h5>
                <div className="text-dark">{tt("pages.mainland.desc2")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col>
              <Img
                className="rounded mx-auto d-block pb-3"
                fluid={data.img1.childImageSharp.fluid}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">
                  <a className="anchor" id="foshan" />
                  {tt("pages.mainland.title4")}
                </h5>
                <div className="text-dark">{tt("pages.mainland.desc3")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col>
              <Img
                className="rounded mx-auto d-block pb-3"
                fluid={data.img2.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
